import React from "react";
import ConfigurationService, { IJurisdiction } from "../services/ConfigurationService";
import { ApiClient } from "../services/ApiClient";
import { IQuestionData, LabelId, LanguageId, QuestionId } from "../shared/multi-language";

/**
 * Fields that are overridable by jurisdiction
 */
export type JurisdictionLabelId = "languageTooltip" | "welcome" | "opening" | "attestation"
    | "authRepAttestation" | "ineligibleClosing" | "eligibleClosing"
    | "existingRecordFound" | "existingUpdatedEligible" | "existingUpdatedIneligible" | "exportedClosing";
export interface IAppContext {
    config: ConfigurationService;
    jurisdiction: IJurisdiction;
    api: ApiClient;
    lang: LanguageId;
    getQuestion: (id: QuestionId) => IQuestionData;
    getLabel: (id: LabelId) => string;
    getJurisdictionLabel: (id: JurisdictionLabelId) => string;
}

const defaultContext: IAppContext = {
    config: null as any,
    jurisdiction: null as any,
    api: null as any,
    lang: null as any,
    getQuestion: null as any,
    getLabel: null as any,
    getJurisdictionLabel: null as any,
};
const AppContext = React.createContext(defaultContext);

export default AppContext;

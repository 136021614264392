import React from "react";
import "./App.css";
import { Header } from "./components/base/Header/Header";
import { EligibilityForm } from "./components/EligibilityForm";
import { AppContextProvider } from "./contexts/AppContextProvider";

import "react-datepicker/dist/react-datepicker.css";
import { CoBrandHeader } from "./components/base/Header/CoBrandHeader";
import { UrlHelper } from "./shared/url.helper";
import { Footer } from "./components/base/Footer/Footer";
import { Grid } from "semantic-ui-react";

export default class App extends React.Component {
    render() {
        const renderHeader = () => {
            const jurisdiction = UrlHelper.get("jurisdiction");
            if (!jurisdiction) {
                return <Header />;
            } else {
                return <CoBrandHeader />;
            }
        };

        return (
            <AppContextProvider>
                {renderHeader()}
                <main>
                    <Grid container>
                    <EligibilityForm />
                    </Grid>
                </main>
                <Footer />
            </AppContextProvider>
        );
    }
}

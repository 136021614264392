import { EligibilityFormData, SubmitFormResponse } from "../shared/api-interfaces";
import ConfigurationService from "./ConfigurationService";
import { getLogger, Logger } from "./LoggingService";
export class ApiClient {
  logger: Logger;

  constructor(private cfg: ConfigurationService) {
    this.logger = getLogger("ApiClient");
  }

  private async request(endpoint: string, method: string, body?: any): Promise<Response> {

    const response = await fetch(this.cfg.apiUrl + endpoint, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: { 'Content-Type': 'application/json' },
    });

    if(response.status === 400){
      const errors = await response.json();
      this.logger.error("Fetch failed", response.status, errors);

      const message = errors.message 
        ? errors.message 
        : Object.keys(errors).map(k=> Array.isArray(errors[k]) ? `${errors[k].join(", ")}` : errors[k]).join(". ");
      throw new Error(message);
    }

    return response;
  }

  async post(endpoint: string, body: any): Promise<Response> {
    return this.request(endpoint, "POST", body);
  }

  /**
   * Requests an OTP code for current form. Returns OTP ID.
   */
  async sendOtp(form: EligibilityFormData): Promise<string> {
    const result = await this.post("/public/otp", form);
    const json = await result.json();

    if (result.status === 200) {
      return json.id
    }

    this.logger.error("Submit failed", result.status, json);
    throw new Error("There was an error. Please try again later.");
  }

  async submit(form: EligibilityFormData): Promise<SubmitFormResponse> {
    const result = await this.post("/public/submit", form);
    const json = await result.json();

    if (result.status === 200) {
      return json;
    }

    this.logger.error("Submit failed", result.status, json);
    throw new Error("There was an error. Please try again later.");
  }
}

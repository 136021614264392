import React, { useContext, useEffect } from "react";
import { Button, Grid, Icon, Image, Popup } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import { UrlHelper } from "../../../shared/url.helper";
import "./Header.css";

export function Header() {
    const ctx = useContext(AppContext);

    const toggleLanguage = () => {
        const next = ctx.lang === "es" ? "en" : "es";
        const queryString = UrlHelper.upsertParams({
            lang: next,
            jurisdiction: ctx.jurisdiction.id,
        });
        window.location.href = `${window.location.origin}${window.location.pathname}${queryString}`;
    };

    return (
        <div className="static-header">
            <Grid container columns={1}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column>
                        <header className="responsive-header">
                            <div className="header-basic-branding" role="heading" aria-level={1}>
                                <Image src="logo-light.png" alt="VAMS Logo" />
                                <h1>
                                    Vaccine Administration <br />
                                    Management System
                                </h1>
                            </div>
                            {ctx.jurisdiction.languageEnabled && (
                                <div className="language-wrapper">
                                    <Popup
                                        content={ctx.getJurisdictionLabel("languageTooltip")}
                                        trigger={
                                            <Button
                                                color="blue"
                                                basic
                                                icon
                                                labelPosition="left"
                                                className="langButton"
                                                onClick={toggleLanguage}
                                            >
                                                <Icon name="globe" />
                                                {ctx.lang === "es" ? "English" : "Español"}
                                            </Button>
                                        }
                                    />
                                </div>
                            )}
                        </header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

import { Formik, getIn } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { CheckboxProps, Form, InputOnChangeData, Segment, SemanticWIDTHS } from "semantic-ui-react";
import { AppCheckboxGroup, AppDate, AppDropdown, AppRadioGroup } from "./AppFormInputs";
import { getAgeRange } from "../../shared/api-interfaces";
import { AppLabel } from "./AppLabel";
import moment from "moment";
import AppContext from "../../contexts/AppContext";
import { IOption } from "../../shared/multi-language";

const getErrorText = (formik: any, name: string): any => {
    return getIn(formik.touched, name) && getIn(formik.errors, name)
        ? getIn(formik.errors, name)
        : null;
};

interface IQuestionProps {
    disabled?: boolean;
    formik: {
        handleChange: (e: any) => void;
        handleBlur: (e: any) => void;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void>;
        values: { [name: string]: any };
    };
}

interface ITextInputQuestionProps extends IQuestionProps {
    name: string;
    label?: string;
    subLabel?: string | string[];
    required?: boolean;
    width?: SemanticWIDTHS;
}
export function TextInputQuestion(props: ITextInputQuestionProps) {

    const error = getErrorText(props.formik, props.name);

    const handleChange = (e: any, data: InputOnChangeData) => {
        props.formik.setFieldValue(props.name, data.value)
    };

    return (
        <Form.Input
            key={props.name}
            aria-label={props.label}
            label={(props.label || props.subLabel) && <AppLabel label={props.label} subLabel={props.subLabel} required={props.required} className="field-label" />}
            name={props.name}
            onChange={handleChange}
            value={props.formik.values[props.name] || ""}
            disabled={props.disabled}
            error={error && { content: error }}
            width={8}
        />
    );
};

interface IDateOfBirthQuestionProps extends IQuestionProps {
    required: boolean;
}
export function DateOfBirthQuestion(props: IDateOfBirthQuestionProps) {
    const name = "dob";
    const ctx = useContext(AppContext);

    const handleChanged = (value: Date) => {
        const range = getAgeRange(value, "-1");
        if (range === "-1") {
            props.formik.setFieldValue("age", "");
            props.formik.setFieldValue(name, "");
            return;
        }

        props.formik.setFieldValue("age", range)

        const formatted = getFormattedDate(value)
        props.formik.setFieldValue(name, formatted)
    };

    const value = props.formik.values[name]
        ? moment(props.formik.values[name], "YYYY-MM-DD").toDate()
        : null;

    console.log(props.formik.values[name])

    return (
        <Segment>
            <AppDate
                locale={ctx.lang}
                name={name}
                label={ctx.getLabel(name)}
                subLabel={ctx.getLabel("dobFormat")}
                disabled={props.disabled}
                value={value}
                onChanged={(value) => handleChanged(value)}
                error={getErrorText(props.formik, name)}
                required={props.required}
            />
        </Segment>
    );
};

export function EmailQuestion(props: IQuestionProps) {
    const name = "email";

    const [value, setValue] = useState("");
    const [confirm, setConfirm] = useState("");
    const [error, setError] = useState(null as string);

    const ctx = useContext(AppContext);

    useEffect(() => {
        if (confirm) {
            if (value !== confirm) {
                setError(ctx.getLabel("emailMismatch"));
            } else {
                setError(null);
                props.formik.setFieldValue(name, value);
            }
        } else {
            props.formik.setFieldValue(name, "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, confirm]);

    useEffect(() => {
        setValue(props.formik.values.authRepEmail)
        setConfirm(props.formik.values.authRepEmail)
    }, [props.formik.values.authRepEmail, props.formik.values.isAuthRep])

    const required = props.formik.values.preferredContactMethod === "email";

    return (
        <>
            <Form.Input
                aria-label={ctx.getLabel("email")}
                name={name}
                label={<AppLabel label={ctx.getLabel("email")} required={required} className="field-label" />}
                disabled={props.disabled}
                onChange={(e: any, data: InputOnChangeData) => {
                    setValue(data.value);
                }}
                error={getErrorText(props.formik, name)}
                width={"8"}
                value={value}
            />
            <Form.Input
                aria-label={ctx.getLabel("confirmEmail")}
                name="confirmEmail"
                label={<AppLabel label={ctx.getLabel("confirmEmail")} required={required} className="field-label" />}
                disabled={props.disabled}
                onChange={(e: any, data: InputOnChangeData) => {
                    setConfirm(data.value);
                }}
                error={error || getErrorText(props.formik, name)}
                width={"8"}
                value={confirm}
            />
        </>
    );
};

export function PhoneQuestion(props: IQuestionProps) {
    const name = "phone";
    const ctx = useContext(AppContext);

    useEffect(() => {
        props.formik.setFieldValue(name, props.formik.values.authRepPhone);
    }, [props.formik.values.authRepPhone, props.formik.values.isAuthRep])

    return (
        <TextInputQuestion
            name={name}
            label={ctx.getLabel(name)}
            disabled={props.disabled}
            formik={props.formik}
            required={props.formik.values.preferredContactMethod === "sms"}
        />
    );
}

export function JurisdictionQuestion(props: IQuestionProps) {
    const name = "jurisdiction";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <Segment>
            <AppDropdown
                name={name}
                label={config.label}
                subLabel={config.subLabel}
                subLabelLink={config.subLabelLink}
                options={config.options}
                disabled={props.disabled}
                value={props.formik.values[name]}
                onChanged={(value) => {
                    props.formik.setFieldValue(name, value);
                }}
                error={getErrorText(props.formik, name)}
            />
        </Segment>
    );
};

export function AgeQuestion(props: IQuestionProps) {
    const name = "age";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
                if (props.formik.values.dob) {
                    props.formik.setFieldValue("dob", "");
                }
            }}
            error={getErrorText(props.formik, name)}
            required={true}
        />
    );
};

export function LivingSituationQuestion(props: IQuestionProps) {
    const name = "livingSituation";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function RuralQuestion(props: IQuestionProps) {
    const name = "rural";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function HealthcareQuestion(props: IQuestionProps) {
    const name = "healthcare";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function FrontlineWorkerQuestion(props: IQuestionProps) {
    const name = "frontlineWorker";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function EssentialWorkerQuestion(props: IQuestionProps) {
    const name = "essentialWorker";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function ConditionsQuestion(props: { formik: any; disabled?: boolean }) {
    const name = "conditions";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppCheckboxGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function PreferredContactMethod(props: IQuestionProps) {
    const name = "preferredContactMethod";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);

    const [value, setValue] = useState(props.formik.values[name]);
    const handleClick = (newVal: any) => {
        if (props.disabled) {
            return;
        }
        setValue(newVal);
        props.formik.setFieldValue(name, newVal);
    };

    const renderOption = (option: IOption) => {
        const key = `${name}-${option.value}`;
        return (
            <Form.Radio
                key={key}
                label={option.text}
                aria-label={option.text}
                name={name}
                value={option.value as string}
                checked={value === option.value}
                onClick={() => handleClick(option.value)}
                disabled={props.disabled}
            />
        );
    };

    return (
        <Form.Group grouped>
            <AppLabel label={config.label} subLabel={config.subLabel} subLabelLink={config.subLabelLink} required={true} className="app-label-small" />
            <Form.Group inline>
                {config.options.map(renderOption)}
            </Form.Group>
        </Form.Group>
    );
};

export function AuthorizedRepresentativeQuestion(props: IQuestionProps) {
    const name = "isAuthRep";
    const firstName = "authRepFirstName";
    const lastName = "authRepLastName";
    const relationship = "authRepRelationship";
    const phone = "authRepPhone";
    const email = "authRepEmail";

    const ctx = useContext(AppContext);
    const isAuthRepConfig = ctx.getQuestion(name);
    const relationshipConfig = ctx.getQuestion(relationship);

    const handleClick = (e: any, d: CheckboxProps) => {
        if (props.disabled) {
            return;
        }
        props.formik.setFieldValue(name, d.checked ? "true" : "false")
    };

    const renderMainQuestion = () => {
        return (
            <Form.Group>
                <Form.Checkbox disabled={props.disabled} checked={props.formik.values[name] === "true"} onClick={handleClick} label={isAuthRepConfig.label} />
            </Form.Group>
        );
    };

    const { preferredContactMethod } = props.formik.values;

    if (props.formik.values[name] === 'true') {
        return (
            <>
                {renderMainQuestion()}
                <Form.Group widths="equal">
                    <TextInputQuestion
                        name={firstName}
                        label={ctx.getLabel(firstName)}
                        disabled={props.disabled}
                        formik={props.formik}
                        required
                    />
                    <TextInputQuestion
                        name={lastName}
                        label={ctx.getLabel(lastName)}
                        disabled={props.disabled}
                        formik={props.formik}
                        required
                    />
                    <Form.Select
                        name={relationship}
                        aria-label={relationshipConfig.label}
                        label={<AppLabel label={relationshipConfig.label} className="field-label" />}
                        disabled={props.disabled}
                        onChange={(e: any, data: InputOnChangeData) => {
                            props.formik.setFieldValue(relationship, data.value);
                        }}
                        options={relationshipConfig.options}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TextInputQuestion
                        name={phone}
                        formik={props.formik}
                        label={ctx.getLabel(phone)}
                        disabled={props.disabled}
                        required={preferredContactMethod === "sms"}
                    />
                    <TextInputQuestion
                        name={email}
                        formik={props.formik}
                        label={ctx.getLabel(email)}
                        disabled={props.disabled}
                        required={preferredContactMethod === "email"}
                    />

                </Form.Group>
                <AuthRepDateOfBirthQuestion required={false} formik={props.formik} />
            </>
        );
    }
    return renderMainQuestion();
};

export function MonkeypoxExposureQuestion(props: IQuestionProps) {
    const name = "monkeypoxExposure";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppCheckboxGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function MonkeypoxGroup(props: { formik: any; disabled?: boolean }) {
    const name = "monkeypoxGroup";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppRadioGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function MonkeypoxSymptoms(props: { formik: any; disabled?: boolean }) {
    const name = "monkeypoxSymptoms";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppCheckboxGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function MonkeypoxImmunocompromisingConditions(props: { formik: any; disabled?: boolean }) {
    const name = "conditions";
    const ctx = useContext(AppContext);
    const config = ctx.getQuestion(name);
    return (
        <AppCheckboxGroup
            name={name}
            label={config.label}
            subLabel={config.subLabel}
            subLabelLink={config.subLabelLink}
            options={config.options}
            disabled={props.disabled}
            value={props.formik.values[name]}
            onChanged={(value) => {
                props.formik.setFieldValue(name, value);
            }}
            error={getErrorText(props.formik, name)}
        />
    );
};

export function AuthRepDateOfBirthQuestion(props: IDateOfBirthQuestionProps) {
    const name = "authRepDob";
    const ctx = useContext(AppContext);

    const handleChanged = (value: Date) => {
        const range = getAgeRange(value, "-1");
        if (range === "-1") {
            props.formik.setFieldValue("age", "");
            props.formik.setFieldValue(name, "");
            return;
        }

        props.formik.setFieldValue("age", range)

        const formatted = getFormattedDate(value)
        props.formik.setFieldValue(name, formatted)
    };

    const value = props.formik.values[name]
        ? moment(props.formik.values[name], "YYYY-MM-DD").toDate()
        : null;

    return (
        <AppDate
            locale={ctx.lang}
            name={name}
            label={ctx.getLabel(name)}
            subLabel={ctx.getLabel("dobFormat")}
            disabled={props.disabled}
            value={value}
            onChanged={(value) => handleChanged(value)}
            error={getErrorText(props.formik, name)}
            required={props.required}
        />
    );
};

/**
 * Format YYYY-MM-DD for VAMS
 */
const getFormattedDate = (date: Date): string => {
    if (!date) {
        return "";
    }
    return moment(date).format('YYYY-MM-DD')
};
